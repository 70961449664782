export enum HotelStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export enum HotelType {
	RESORT = 'resort',
	HOTEL = 'hotel',
	LIVEABOARD = 'liveaboard',
	GUEST_HOUSE = 'guest_house',
}

export enum RoomStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export enum RestaurantStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}
